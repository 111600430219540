import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config/config.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedNewService } from 'src/app/services/shared-new/shared-new.service';
export class SelectAddonPage {
    constructor(sharedNewService, configService, modalController, labelService) {
        this.sharedNewService = sharedNewService;
        this.configService = configService;
        this.modalController = modalController;
        this.labelService = labelService;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // this.template = await this.sharedNewService.getTemplateData(this.item.templateId);
            this.currencyCode = this.configService.environment.currencyCode;
        });
    }
    getSelectOptionTxt(section) {
        let txt = '';
        switch (section.multiple) {
            case true:
                txt = `Select up to ${section.options.length} options`;
                break;
            case false:
                txt = `Select 1 out of ${section.options.length} options`;
                break;
        }
        return txt;
    }
    selectOption(sectionIndex, option, optionIndex) {
        option.checked = !option.checked;
        if (!this.template.sections[sectionIndex].multiple) {
            if (option.checked) {
                this.template.sections[sectionIndex].options.forEach((el, index) => {
                    if (optionIndex !== index) {
                        el.checked = false;
                    }
                });
            }
        }
        this.getAddonsPrice();
    }
    onChangeOption(event, sectionIndex) {
        const optionIndex = event.target.value;
        this.template.sections[sectionIndex].options.forEach((el, index) => {
            if (optionIndex !== index) {
                el.checked = false;
            }
            else {
                el.checked = true;
            }
        });
        this.getAddonsPrice();
    }
    getSelectedOptions() {
        let selectedOptions = [];
        this.template.sections.forEach(section => {
            let options = section.options.filter(option => option.checked);
            if (section.required && !section.multiple && !options.length) {
                section.options[0].checked = true;
                options = [section.options[0]];
            }
            selectedOptions.push({ name: section.name, options });
        });
        return selectedOptions;
    }
    getAddonsPrice() {
        let totalOptionsPrice = 0;
        let selectedOptions = this.getSelectedOptions();
        selectedOptions.forEach(el => {
            el.options.forEach(option => {
                totalOptionsPrice += option.price;
            });
        });
        return totalOptionsPrice || 0;
    }
    getActiveSections() {
        const activeSections = this.template.sections.filter(section => section.active);
        return activeSections.length ? activeSections : null;
    }
    addItems() {
        const isValid = this.checkAnyRequiredSection();
        if (!isValid) {
            this.sharedNewService.presentAlert({
                msg: this.labelService.labels['SELECT_ADDON']['select_required_options'],
                backdropDismiss: true,
                page: ''
            });
            return;
        }
        this.modalController.dismiss({ totalPrice: this.getAddonsPrice(), selectedOptions: this.getSelectedOptions(), closed: false });
    }
    checkAnyRequiredSection() {
        let isValid = true;
        for (const section of this.template.sections) {
            let options = section.options.filter(option => option.checked);
            if (section.required && section.multiple && !options.length) {
                isValid = false;
                break;
            }
        }
        return isValid;
    }
    closeModal() {
        this.modalController.dismiss({ totalPrice: 0, selectedOptions: [], closed: true });
    }
    isCheckboxDisabled(optionIndex, sectionIndex) {
        let isDisabled = false;
        const section = this.template.sections[sectionIndex];
        if (!section.multiple && !section.required) {
            this.template.sections[sectionIndex].options.forEach((option, index) => {
                if (optionIndex !== index) {
                    if (option.checked) {
                        isDisabled = true;
                    }
                }
            });
        }
        return isDisabled;
    }
}
