import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';
import { Storage } from '@ionic/storage';
import { SelectAddressPage } from '../../select-address/select-address.page';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ProductUtilsService } from 'src/app/services/product-utils/product-utils.service';
export class BookingSchedulePage {
    constructor(userService, bookingService, sharedService, labelService, router, configService, modalController, storage, paymentService, productUtilsService) {
        this.userService = userService;
        this.bookingService = bookingService;
        this.sharedService = sharedService;
        this.labelService = labelService;
        this.router = router;
        this.configService = configService;
        this.modalController = modalController;
        this.storage = storage;
        this.paymentService = paymentService;
        this.productUtilsService = productUtilsService;
        this.scheduleSlots = [];
        this.selectedTime = {};
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.getSchedules();
            this.address = yield this.storage.get('userDefaultAddress');
            this.currencyCode = this.configService.environment.currencyCode;
            this.userId = this.userService.getUserId();
            this.taxType = this.configService.environment.taxType;
        });
    }
    getSchedules() {
        if (this.data.scheduleData.active) {
            const maxDays = this.data.scheduleData.maxDays;
            const schedules = this.data.scheduleData.schedules;
            for (let index = 0; index < maxDays; index++) {
                let dayToCheck = moment().add(index, 'days');
                const schedule = schedules.filter(s => s.day === dayToCheck.format('dddd'));
                if (schedule.length) {
                    if (schedule[0].active && schedule[0].schedule.length) {
                        this.scheduleSlots.push({
                            date: dayToCheck.toDate(),
                            schedules: schedule[0].schedule,
                            day: schedule[0].day,
                            active: false
                        });
                    }
                }
            }
            if (this.scheduleSlots.length) {
                this.scheduleSlots[0].active = true;
            }
        }
        console.log('this.scheduleSlots', this.scheduleSlots);
    }
    selectDate(i) {
        this.scheduleSlots[i].active = true;
        this.scheduleSlots.forEach((slot, index) => {
            if (index !== i) {
                slot.active = false;
            }
        });
        this.selectedTime = {};
    }
    getActiveTimeSchedules() {
        return this.scheduleSlots.filter(s => s.active === true)[0].schedules;
    }
    setTimeSlot(event) {
        this.selectedTime = event.target.value || {};
    }
    bookService() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.sharedService.presentLoading();
            const scheduleSlot = this.scheduleSlots.filter(s => s.active === true)[0];
            const data = {
                item: Object.assign({}, this.data.item, { price: this.getTotalAmount().totalAmount, totalGst: this.getTotalAmount().totalGst }),
                schedule: this.data.scheduleData.active ? {
                    active: true,
                    date: moment(scheduleSlot.date).format('DD-MM-YYYY'),
                    slot: {
                        start: this.selectedTime.start,
                        end: this.selectedTime.end
                    },
                    day: scheduleSlot.day
                } : null,
                vendor: this.data.vendor,
                user: {
                    id: this.userService.getUserId(),
                    name: this.userService.getUserName(),
                    address: this.address || null
                },
                status: this.isAllowPayment() ? 'pending' : 'accepted',
                payment: {
                    required: this.isAllowPayment()
                },
                additionalInfo: this.data.item.additionalInfo
            };
            const res = yield this.bookingService.confirmBooking(data, this.selectedTime && this.selectedTime.slotLimit ? this.selectedTime.slotLimit : 0);
            this.sharedService.loading.dismiss();
            if (res.status === 'added') {
                this.modalController.dismiss();
                if (this.isAllowPayment()) {
                    const paymentModalObj = {
                        order: {
                            amount: this.getTotalAmount().totalAmount,
                            id: res.id,
                            collection: 'bookings',
                            gst: data.item.gst,
                            userId: this.userId
                        },
                        successRoute: 'user-booking'
                    };
                    this.openPaymentModal(paymentModalObj);
                }
                else {
                    this.sharedService.presentAlert(this.labelService.labels['BOOKING_SCHEDULE']['service_booked']);
                    this.router.navigate(['user-booking']);
                }
            }
            else if (res.status === 'not_available') {
                this.sharedService.presentAlert(this.labelService.labels['BOOKING_SCHEDULE']['slot_not_available']);
            }
            else {
                this.sharedService.presentAlert(this.labelService.labels['SHARED']['some_issue_msg']);
            }
        });
    }
    getDate(date) {
        return moment(date).format('DD MMM');
    }
    isBookNowDisabled() {
        return this.data.scheduleData.active && !Object.keys(this.selectedTime).length;
    }
    isAllowPayment() {
        return this.data.item.allowPayment;
    }
    convert24to12(time) {
        return this.sharedService.convert24to12(time);
    }
    onClickChangeOrAddAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: SelectAddressPage,
                cssClass: 'custom-modal',
                componentProps: { pageType: 'modal' }
            });
            modal.onDidDismiss().then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (res && res.data && !res.data.closed) {
                    this.address = yield this.storage.get('userDefaultAddress');
                }
            }));
            yield modal.present();
        });
    }
    openPaymentModal(paymentModalObj) {
        this.paymentService.openPaymentModal(paymentModalObj);
    }
    getBookingDuration() {
        return this.productUtilsService.getBookingDuration(this.data);
    }
    getTotalAmount() {
        let totalGst = 0;
        let gstOnItem = 0;
        let gstOnAddons = 0;
        let totalAmount = this.data.item.price + this.data.item.addOns.totalPrice;
        if (this.data.item.gst.isExclusive) {
            gstOnItem = this.data.item.price * (this.data.item.gst.value / 100);
            gstOnAddons = this.data.item.addOns.totalPrice * (this.data.item.gst.value / 100);
            totalAmount += gstOnItem + gstOnAddons;
        }
        else {
            gstOnItem = this.data.item.price - (this.data.item.price / (1 + (this.data.item.gst.value / 100)));
            gstOnAddons = this.data.item.addOns.totalPrice - (this.data.item.addOns.totalPrice / (1 + (this.data.item.gst.value / 100)));
        }
        totalGst += gstOnItem + gstOnAddons;
        this.data.item.gst.total = totalGst;
        this.data.item.addOns.totalGst = gstOnAddons;
        return { totalGst, totalAmount };
    }
    modalDismiss() {
        this.modalController.dismiss();
    }
    isCustomInputAvailable() {
        return this.data && this.data.item && this.data.item.additionalInfo && this.data.item.additionalInfo.customInput && this.data.item.additionalInfo.customInput.active;
    }
}
