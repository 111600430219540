<ion-menu
  swipe-gesture="false"
  side="start"
  menuId="first"
  contentId="content1"
  class="custom-menu"
  (ionWillOpen)="menuOpen()"
  (ionWillClose)="menuClose()"
>
  <ion-content>
    <!-- <div class="profile-wrapper" *ngIf="loginStatus">
      <img *ngIf="loginStatus && userPic" src="{{userPic}}" alt="">
      <h3 *ngIf="loginStatus" style="text-transform: capitalize;" (click)="goToPage('profile')">{{userName}}</h3>
    </div> -->
    <!-- <div class="profile-wrapper btn" *ngIf="!loginStatus" (click)="openLoginModal()">
      {{'SIDEMENU.login' | translate}}
    </div> -->
    <div class="menu-logo">
      <div
        class="logo menu-logo_align"
        [ngClass]="{ 'logo-custom-size': headerData.logo?.customSize }"
      >
        <a [routerLink]="['']">
          <ng-container *ngIf="headerData?.logo?.logoUrl; else defaultLogo">
            <img [src]="headerData.logo.logoUrl" alt="" />
          </ng-container>
          <ng-template #defaultLogo>
            <img src="../../../assets/img/shop-logo-color.png" />
          </ng-template>
        </a>
      </div>
    </div>

    <ng-container *ngIf="headerData?.designStudioActive; else defaultHeader">
      <ion-list
        *ngIf="headerData.menuData?.show"
        class="new-menu-list"
        lines="none"
      >
        <ion-item
          *ngIf="loginStatus"
          (click)="toggleAccordian('my-account',1)"
          class="menu-item has-dropdown"
          id="my-account-item-1"
        >
          <ion-label>My Account</ion-label>
          <span class="icon account-icon" slot="end"
            ><i class="flaticon-next"></i
          ></span>
        </ion-item>
        <div
          class="sub-dropdown-menu panel"
          id="my-account-menu-1"
          *ngIf="loginStatus"
        >
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item
              [routerLink]="['profile']"
              class="menu-item dropdown-item"
            >
              <ion-label>{{'SIDEMENU.profile' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item
              [routerLink]="['user-order-history']"
              class="menu-item dropdown-item"
            >
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_orders' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="loginStatus && appointmentFeature"
          >
            <ion-item
              (click)="goToPage('appointment-bookings')"
              class="menu-item dropdown-item"
            >
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_bookings' | translate }}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="loginStatus && isWalletActive"
          >
            <ion-item
              [routerLink]="['user-wallet']"
              class="menu-item dropdown-item"
            >
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_wallet' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item
              [routerLink]="['user-addresses']"
              class="menu-item dropdown-item"
            >
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_addresses' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
        </div>
        <ng-container
          *ngFor="let item of headerData.menuData?.menuItems; let i=index"
        >
          <ion-item
            *ngIf="item.dropdownMenu;else noDropdown"
            id="dropdown-item-{{i}}"
            class="menu-item has-dropdown"
            (click)="toggleAccordian('dropdown', i)"
          >
            <ion-label>{{ item.title }}</ion-label>
            <span class="icon" slot="end"><i class="flaticon-next"></i></span>
          </ion-item>
          <ng-template #noDropdown>
            <ion-menu-toggle auto-hide="false">
              <ng-container
                *ngIf="item.type == 'custom'; else itemNoCustomTemplate"
              >
                <ion-item
                  class="menu-item"
                  [href]="item.link != '#' ? item.link : '/'"
                >
                  <ion-label>{{ item.title }}</ion-label>
                </ion-item>
              </ng-container>
              <ng-template #itemNoCustomTemplate>
                <ion-item
                  class="menu-item"
                  [routerLink]="item.link != '#' ? item.link : '/'"
                >
                  <ion-label>{{ item.title }}</ion-label>
                </ion-item>
              </ng-template>
            </ion-menu-toggle>
          </ng-template>

          <div
            id="dropdown-menu-{{i}}"
            class="dropdown-menu panel"
            *ngIf="item.dropdownMenu"
          >
            <ng-container
              *ngFor="let ditem of item.dropdownMenuData; let i=index"
            >
              <ion-item
                (click)="toggleAccordian('sub-dropdown', i)"
                class="menu-item dropdown-item"
                id="sub-dropdown-item-{{i}}"
                *ngIf="ditem.subMenu && ditem.subMenuData;else nosubMenu"
              >
                <ion-label>{{ ditem.title }}</ion-label>
                <span class="icon" slot="end"
                  ><i class="flaticon-next"></i
                ></span>
              </ion-item>
              <ng-template #nosubMenu>
                <ion-menu-toggle auto-hide="false">
                  <ng-container
                    *ngIf="ditem.type == 'custom'; else ditemNoCustomTemplate"
                  >
                    <ion-item
                      class="menu-item dropdown-item"
                      [href]="ditem.link"
                    >
                      <ion-label class="main-headings"
                        >{{ ditem.title }}</ion-label
                      >
                    </ion-item>
                  </ng-container>
                  <ng-template #ditemNoCustomTemplate>
                    <ion-item
                      class="menu-item dropdown-item"
                      [routerLink]="ditem.link"
                    >
                      <ion-label class="main-headings"
                        >{{ ditem.title }}</ion-label
                      >
                    </ion-item>
                  </ng-template>
                </ion-menu-toggle>
              </ng-template>
              <div
                class="sub-dropdown-menu panel"
                id="sub-dropdown-menu-{{i}}"
                *ngIf="ditem.subMenu && ditem.subMenuData"
              >
                <ng-container *ngFor="let subMenuItem of ditem.subMenuData">
                  <ion-menu-toggle auto-hide="false">
                    <ng-container
                      *ngIf="subMenuItem.type == 'custom'; else subMenuItemNoCustomTemplate"
                    >
                      <ion-item
                        class="menu-item subdropdown-item"
                        [href]="subMenuItem.link"
                      >
                        <ion-label class="main-headings"
                          >{{subMenuItem.title}}</ion-label
                        >
                      </ion-item>
                    </ng-container>
                    <ng-template #subMenuItemNoCustomTemplate>
                      <ion-item
                        class="menu-item subdropdown-item"
                        [routerLink]="subMenuItem.link"
                      >
                        <ion-label class="main-headings"
                          >{{subMenuItem.title}}</ion-label
                        >
                      </ion-item>
                    </ng-template>
                  </ion-menu-toggle>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ion-item
          *ngIf="loginStatus"
          (click)="toggleAccordian('my-account',1)"
          class="menu-item account-2"
          id="my-account-item-1"
        >
          <ion-label>My Account</ion-label>
          <span class="icon" slot="end"><i class="flaticon-next"></i></span>
        </ion-item>
        <div
          class="sub-dropdown-menu panel"
          id="my-account-menu-1"
          *ngIf="loginStatus"
        >
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item [routerLink]="['profile']" class="inside-items">
              <ion-label>{{'SIDEMENU.profile' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item
              [routerLink]="['user-order-history']"
              class="inside-items"
            >
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_orders' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="loginStatus && appointmentFeature"
          >
            <ion-item
              (click)="goToPage('appointment-bookings')"
              class="inside-items"
            >
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_bookings' | translate }}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="loginStatus && isWalletActive"
          >
            <ion-item [routerLink]="['user-wallet']" class="inside-items">
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_wallet' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item [routerLink]="['user-addresses']" class="inside-items">
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_addresses' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
        </div>
      </ion-list>
    </ng-container>

    <ng-template #defaultHeader>
      <!-- User Side Menu List -->
      <div class="menu-wrapper">
        <ion-list *ngIf="role === 'user'" lines="none" class="menu-list">
          <!-- Home option -->
          <ion-menu-toggle auto-hide="false">
            <ion-item class="inside-items">
              <i class="flaticon-home"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.home' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /Home option -->
          <!-- Home option -->
          <ion-menu-toggle auto-hide="false">
            <ion-item class="inside-items" [routerLink]="['about']">
              <i class="flaticon-home"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.about' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /Home option -->
          <!-- My Subscriptions option -->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="loginStatus && subscriptionFeature"
          >
            <ion-item
              [routerLink]="['user-subscriptions']"
              class="inside-items"
            >
              <i class="flaticon-order"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_subscriptions' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /My Subscriptions option -->
          <!-- Home option -->
          <ion-menu-toggle auto-hide="false">
            <ion-item class="inside-items" (click)="openChatModal()">
              <i class="flaticon-chat-3"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.chat' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /Home option -->
          <!-- Categories option -->
          <ion-item class="inside-items" (click)="getAllCategories()">
            <i class="flaticon-null-25"></i>
            <ion-label class="main-headings"
              >{{'SIDEMENU.categories' | translate}}</ion-label
            >
            <i class="flaticon-null-28" *ngIf="!showCategories"></i>
            <i class="flaticon-null-27" *ngIf="showCategories"></i>
          </ion-item>
          <div class="categories-list-container" *ngIf="showCategories">
            <div *ngFor="let category of categoriesData; let i = index">
              <ion-item
                [ngClass]="{'categories-list1': i !== categoriesData.length - 1, 'categories-list2': i === categoriesData.length - 1}"
              >
                <ion-menu-toggle auto-hide="false">
                  <ion-label
                    (click)="onClickCategory(category)"
                    class="ion-text-capitalize"
                  >
                    {{category.name}}</ion-label
                  >
                </ion-menu-toggle>
                <span slot="end">
                  <i
                    class="flaticon-download"
                    *ngIf="category.isSubcategories && ((listOfSubcategoriesInView.hasOwnProperty(category.id) && !listOfSubcategoriesInView[category.id].active) || !listOfSubcategoriesInView.hasOwnProperty(category.id))"
                    (click)="getSubCategories(category.id)"
                  ></i>
                  <i
                    class="flaticon-upload"
                    *ngIf="(listOfSubcategoriesInView.hasOwnProperty(category.id) && listOfSubcategoriesInView[category.id].active)"
                    (click)="getSubCategories(category.id)"
                  ></i>
                </span>
                <br />
              </ion-item>
              <div
                *ngIf="listOfSubcategories[category.id] && listOfSubcategories[category.id].length && listOfSubcategoriesInView[category.id].active;"
              >
                <ion-menu-toggle auto-hide="false">
                  <ion-item
                    *ngFor="let subcat of listOfSubcategories[category.id]"
                    (click)="onClickSubcategory(category, subcat)"
                    [ngClass]="{'categories-list1': i !== categoriesData.length - 1, 'categories-list2': i === categoriesData.length - 1}"
                  >
                    <ion-label
                      class="ion-text-capitalize"
                      style="padding-left: 10%"
                      >{{subcat.name}}</ion-label
                    >
                  </ion-item>
                </ion-menu-toggle>
              </div>
            </div>
          </div>
          <!-- /Categories option -->
          <!-- Brands option -->
          <ion-item
            class="inside-items"
            (click)="getAllBrands()"
            *ngIf="brands && brands.length"
          >
            <i class="flaticon-brand"></i>
            <ion-label class="main-headings"
              >{{'SIDEMENU.brands' | translate}}</ion-label
            >
            <i
              class="flaticon-null-28"
              *ngIf="!showBrands && !showBrandsLoader"
            ></i>
            <i
              class="flaticon-null-27"
              *ngIf="showBrands && !showBrandsLoader"
            ></i>
            <ion-spinner
              color="dark"
              name="dots"
              *ngIf="showBrandsLoader"
            ></ion-spinner>
          </ion-item>
          <div class="categories-list-container" *ngIf="showBrands">
            <div *ngFor="let brand of brands; let i = index">
              <ion-item
                [ngClass]="{'categories-list1': i !== brands.length - 1, 'categories-list2': i === brands.length - 1}"
                [routerLink]="['/shop/brand/',urlSlug(brand.name),  brand.id]"
              >
                <ion-menu-toggle auto-hide="false">
                  <ion-label class="ion-text-capitalize">
                    {{brand.name}}</ion-label
                  >
                </ion-menu-toggle>
              </ion-item>
            </div>
          </div>
          <!-- /Brands option -->
          <!-- Services & Requests option -->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="loginStatus && isServiceActive"
          >
            <ion-item [routerLink]="['user-services']" class="inside-items">
              <i class="flaticon-technical-support"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.services' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /Services & Requests option -->
          <!-- my membership option -->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="loginStatus && isMembership"
          >
            <ion-item [routerLink]="['membership']" class="inside-items">
              <i class="flaticon-null-4"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_membership' | translate }}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /my membership option -->
          <!-- Offers option -->
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerLink]="['all-offers']" class="inside-items">
              <i class="flaticon-discount-1"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.offers' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /Offers option -->
          <!-- My Orders option -->
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item
              [routerLink]="['user-order-history']"
              class="inside-items"
            >
              <i class="flaticon-accept" slot="start"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_orders' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /My Orders option -->
          <!-- My bookings -->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="loginStatus"
          >
            <ion-item
              (click)="goToPage('user-booking')"
              class="inside-items"
            >
              <i class="flaticon-business-and-finance"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_bookings' | translate }}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /My bookings -->
          <!-- My Orders option -->
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus && !RFQFeature">
            <ion-item [routerLink]="['user-cart']" class="inside-items">
              <i class="flaticon-shopping-bag-1" slot="start"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_cart' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /My Orders option -->
          <!-- My Wallet option -->
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="loginStatus && isWalletActive"
          >
            <ion-item [routerLink]="['user-wallet']" class="inside-items">
              <i class="flaticon-wallet"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_wallet' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /My Wallet option -->
          <!-- Feedback option -->
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item [routerLink]="['user-wishlist']" class="inside-items">
              <i class="flaticon-like"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_wishlist' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /Feedback option -->
          <!-- address option -->
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item [routerLink]="['user-addresses']" class="inside-items">
              <i class="flaticon-like"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.my_addresses' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /address option -->
          <!-- Profile option -->
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item [routerLink]="['profile']" class="inside-items">
              <i class="flaticon-user-1"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.profile' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /Profile option -->
          <!-- <ion-menu-toggle auto-hide="false" *ngIf="loginStatus && isReferralActive">
          <ion-item (click)="goToPage('user-referral')" class="inside-items share-option">
            <i class="flaticon-share"></i>
            <ion-label class="main-headings">{{'SIDEMENU.share_and_earn' | translate}}</ion-label>
          </ion-item>
        </ion-menu-toggle> -->
          <!-- Feedback option -->
          <ion-menu-toggle auto-hide="false" *ngIf="loginStatus">
            <ion-item [routerLink]="['user-feedback']" class="inside-items">
              <i class="flaticon-comment"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.feedback' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <!-- /Feedback option -->
          <ion-menu-toggle auto-hide="false">
            <ion-item
              [routerLink]="['terms-and-conditions']"
              class="inside-items"
            >
              <i class="flaticon-terms-and-conditions"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.terms_and_conditions' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item class="inside-items" [routerLink]="['privacy-policy']">
              <i class="flaticon-terms-and-conditions"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.privacy_policy' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item
              class="inside-items"
              [routerLink]="['cancellation-policy']"
            >
              <i class="flaticon-terms-and-conditions"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.cancellation_policy' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerLink]="['refund-policy']" class="inside-items">
              <i class="flaticon-terms-and-conditions"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.refund_policy' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerLink]="['shipping-policy']" class="inside-items">
              <i class="flaticon-terms-and-conditions"></i>
              <ion-label class="main-headings"
                >{{'SIDEMENU.shipping_policy' | translate}}</ion-label
              >
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </div>
      <!-- /User Side Menu List -->
    </ng-template>
  </ion-content>
  <ion-footer>
    <ion-button
      (click)="logout()"
      expand="full"
      *ngIf="loginStatus"
      class="log-in-btn"
    >
      <ion-icon name="log-out" slot="start"></ion-icon>
      {{'SIDEMENU.logout' | translate}}
    </ion-button>
    <ion-button
      (click)="openLoginModal()"
      expand="full"
      *ngIf="!loginStatus"
      class="log-in-btn"
    >
      <ion-icon name="log-in" slot="start"></ion-icon>
      {{'SIDEMENU.login' | translate}}
    </ion-button>
  </ion-footer>
</ion-menu>
