import * as tslib_1 from "tslib";
import { ModalController, Platform } from '@ionic/angular';
import { PaymentModulePage } from 'src/app/pages/payment-module/payment-module.page';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import { ConfigService } from '../config/config.service';
import { UserService } from '../user/user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { LabelService } from '../label/label.service';
import { SharedNewService } from '../shared-new/shared-new.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic/storage";
import * as i3 from "../config/config.service";
import * as i4 from "../user/user.service";
import * as i5 from "@angular/fire/firestore";
import * as i6 from "../label/label.service";
import * as i7 from "../shared-new/shared-new.service";
import * as i8 from "@angular/fire/storage";
export class PaymentService {
    constructor(modalController, storage, configService, userService, platform, afs, labelService, sharedNewService, fbStorage) {
        this.modalController = modalController;
        this.storage = storage;
        this.configService = configService;
        this.userService = userService;
        this.platform = platform;
        this.afs = afs;
        this.labelService = labelService;
        this.sharedNewService = sharedNewService;
        this.fbStorage = fbStorage;
    }
    openPaymentModal(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: PaymentModulePage,
                cssClass: 'custom-modal',
                backdropDismiss: false,
                componentProps: { paymentData: data },
            });
            yield modal.present();
        });
    }
    getPartialPaymentObj(data) {
        return {
            cod: data.order.partialPayment.cod,
            online: data.order.amount,
            status: 'pending'
        };
    }
    razorpayMethod(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                data.order.amount = this.getPayableAmount(data.order);
                const paymentRes = {
                    completed: false,
                    mode: 'razorpay',
                    status: 'pending',
                    details: null,
                    walletAmount: data.order.walletAmount,
                    cashbackAmount: data.order.cashbackAmount
                };
                if (data.order.partialPayment.status) {
                    paymentRes.partial = this.getPartialPaymentObj(data);
                }
                let createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
                const createOrderRes = yield createOrderInRazorpay({ amount: Math.round(data.order.amount * 100), orderDocId: data.order.id });
                const razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                console.log('razorpayOrderId', razorpayOrderId);
                const historyObj = {
                    orderId: data.order.id,
                    orderAmount: data.order.amount,
                    userId: data.order.userId,
                    type: data.order.collection,
                    mode: 'razorpay',
                    walletAmount: data.order.walletAmount,
                    cashbackAmount: data.order.cashbackAmount
                };
                if (razorpayOrderId) {
                    const storeInfo = yield this.storage.get('storeInfo');
                    const options = {
                        order_id: razorpayOrderId,
                        description: this.configService.environment.razorpay.description,
                        currency: this.configService.environment.razorpay.currency,
                        key: data.paymentCreds.key,
                        amount: Math.round(data.order.amount * 100),
                        name: storeInfo.storeName ? storeInfo.storeName : '',
                        image: this.configService.environment.razorpay.image,
                        prefill: {
                            method: data.method,
                            contact: this.userService.getPhoneNo(),
                            name: this.userService.getUserName(),
                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                        },
                        theme: this.configService.environment.razorpay.theme,
                        modal: {
                            ondismiss: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                resolve({ status: 'failed', response: paymentRes });
                            })
                        },
                        handler: (handlerResponse) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            console.log('handlerResponse', handlerResponse);
                            let verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                            const apiBody = {
                                razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                razorpay_order_id: handlerResponse.razorpay_order_id,
                                razorpay_signature: handlerResponse.razorpay_signature,
                            };
                            const txnRes = { paymentId: apiBody.razorpay_payment_id };
                            historyObj.details = txnRes;
                            yield this.createPaymentHistory(historyObj);
                            paymentRes.details = txnRes;
                            const verifySignatureRes = yield verifySignature(apiBody);
                            console.log('verifySignatureRes', verifySignatureRes);
                            if (verifySignatureRes.data.signatureIsValid) {
                                paymentRes.completed = true;
                                paymentRes.status = 'completed';
                                resolve({ status: 'success', response: paymentRes });
                            }
                            else {
                                resolve({ status: 'failed', response: paymentRes });
                            }
                        })
                    };
                    var razorpay = new Razorpay(options);
                    razorpay.open();
                    razorpay.on('payment.failed', (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        console.log('failure response', response);
                        const paymentId = response.error && response.error.metadata && response.error.metadata.payment_id ? response.error.metadata.payment_id : '';
                        if (paymentId) {
                            const txnRes = { paymentId: paymentId };
                            historyObj.details = txnRes;
                            yield this.createPaymentHistory(historyObj);
                            paymentRes.details = txnRes;
                        }
                        resolve({ status: 'failed', response: paymentRes });
                    }));
                }
                else {
                    resolve({ status: 'failed', response: paymentRes });
                }
            }));
        });
    }
    cashMethod(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                data.order.amount = this.getPayableAmount(data.order);
                const paymentRes = {
                    completed: false,
                    mode: 'cash',
                    details: {
                        amount: data.order.amount
                    }
                };
                if (data.order.walletAmount || data.order.cashbackAmount) {
                    const historyObj = {
                        orderId: data.order.id,
                        orderAmount: data.order.amount,
                        details: {
                            amount: data.order.amount
                        },
                        userId: data.order.userId,
                        type: data.order.collection,
                        mode: 'cash',
                        walletAmount: data.order.walletAmount,
                        cashbackAmount: data.order.cashbackAmount
                    };
                    yield this.createPaymentHistory(historyObj);
                }
                resolve({ status: 'success', response: paymentRes });
            }));
        });
    }
    paytmMethod(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const paymentRes = {
                    completed: false,
                    mode: 'paytm',
                    status: 'pending',
                    details: null
                };
                if (data.order.partialPayment.status) {
                    paymentRes.partial = this.getPartialPaymentObj(data);
                }
                try {
                    const mobileNo = this.userService.getPhoneNo().slice(3);
                    data.order.amount = this.getPayableAmount(data.order);
                    let getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
                    getCheckSum({
                        orderId: data.order.id.toString(),
                        customerId: data.order.userId,
                        phoneNo: mobileNo,
                        txnAmount: data.order.amount.toString(),
                    }).then((result) => {
                        console.log('checksum:', result.data.checksum);
                        const paytmParams = {
                            MID: result.data.mid,
                            ORDER_ID: data.order.id.toString(),
                            CUST_ID: data.order.userId,
                            CHANNEL_ID: this.configService.environment.paytm.CHANNEL_ID,
                            TXN_AMOUNT: data.order.amount.toString(),
                            WEBSITE: this.configService.environment.paytm.WEBSITE,
                            CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + data.order.id.toString(),
                            INDUSTRY_TYPE_ID: this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                            MOBILE_NO: mobileNo,
                            CHECKSUMHASH: result.data.checksum,
                            ENVIRONMENT: this.configService.environment.paytm.ENVIRONMENT
                        };
                        let successCallback = (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            if (response.STATUS == "TXN_SUCCESS") {
                                const historyObj = {
                                    orderId: data.order.id,
                                    orderAmount: data.order.amount,
                                    details: response,
                                    userId: data.order.userId,
                                    type: data.order.collection,
                                    mode: 'paytm',
                                    walletAmount: data.order.walletAmount,
                                    cashbackAmount: data.order.cashbackAmount
                                };
                                yield this.createPaymentHistory(historyObj);
                                paymentRes.details = response;
                                paymentRes.completed = true;
                                paymentRes.status = 'completed';
                                resolve({ status: 'success', response: paymentRes });
                            }
                            else {
                                resolve({ status: 'failed', response: paymentRes });
                            }
                        });
                        let failureCallback = (error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            resolve({ status: 'failed', response: paymentRes });
                        });
                        paytm.startPayment(paytmParams, successCallback, failureCallback);
                    }).catch(function (error) {
                        resolve({ status: 'failed', response: paymentRes });
                    });
                }
                catch (error) {
                    resolve({ status: 'failed', response: paymentRes });
                }
            }));
        });
    }
    walletMethod(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const paymentRes = {
                    completed: true,
                    mode: 'wallet',
                    status: 'completed'
                };
                const historyObj = {
                    orderId: data.order.id,
                    orderAmount: data.order.amount,
                    details: {
                        amount: data.order.amount
                    },
                    userId: data.order.userId,
                    type: data.order.collection,
                    mode: 'wallet',
                    walletAmount: data.order.walletAmount,
                    cashbackAmount: data.order.cashbackAmount
                };
                yield this.createPaymentHistory(historyObj);
                resolve({ status: 'success', response: paymentRes });
            }));
        });
    }
    upiManaulMethod(data, paymentImg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const imgRef = this.fbStorage.ref(`upiManualPayment/${data.order.id}/image/` + new Date().getTime().toString() + '.png');
                yield imgRef.putString(paymentImg, 'data_url');
                const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                const paymentRes = {
                    completed: true,
                    mode: 'upiManual',
                    screenshot: downloadURL,
                    status: 'completed'
                };
                if (data.order.walletAmount || data.order.cashbackAmount) {
                    const historyObj = {
                        orderId: data.order.id,
                        orderAmount: data.order.amount,
                        details: {
                            amount: data.order.amount
                        },
                        userId: data.order.userId,
                        type: data.order.collection,
                        mode: 'wallet',
                        walletAmount: data.order.walletAmount,
                        cashbackAmount: data.order.cashbackAmount
                    };
                    yield this.createPaymentHistory(historyObj);
                }
                resolve({ status: 'success', response: paymentRes });
            }));
        });
    }
    customOptionMethod(data, userResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let downloadURL = '';
                if (userResponse.image) {
                    const imgRef = this.fbStorage.ref(`customPayment/${data.order.id}/image/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(userResponse.image, 'data_url');
                    downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                }
                const paymentRes = {
                    completed: true,
                    mode: 'custom',
                    optionName: userResponse.optionName,
                    screenshot: downloadURL,
                    textDetails: userResponse.textDetails,
                    status: 'completed'
                };
                if (data.order.walletAmount || data.order.cashbackAmount) {
                    const historyObj = {
                        orderId: data.order.id,
                        orderAmount: data.order.amount,
                        details: {
                            amount: data.order.amount
                        },
                        userId: data.order.userId,
                        type: data.order.collection,
                        mode: 'wallet',
                        walletAmount: data.order.walletAmount,
                        cashbackAmount: data.order.cashbackAmount
                    };
                    yield this.createPaymentHistory(historyObj);
                }
                resolve({ status: 'success', response: paymentRes });
            }));
        });
    }
    handlePaymentRes(paymentRes, paymentData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.afs.collection(paymentData.order.collection).doc(paymentData.order.id).update({
                payment: Object.assign({}, paymentRes.response),
                status: paymentRes.status === 'success' ? 'accepted' : 'pending'
                // totalGst: firebase.firestore.FieldValue.increment(paymentRes.response.extraChargeOnPayment.gst)
            });
            const alertMessage = paymentRes.status === 'success' ? this.labelService.labels['PAYMENT_SERVICE']['payment_success_msg'] :
                paymentRes.mode === 'razorpay' ? this.labelService.labels['PAYMENT_SERVICE']['razorpay_payment_failed_msg'] :
                    this.labelService.labels['PAYMENT_SERVICE']['payment_failed_msg'];
            this.sharedNewService.presentAlert({ msg: alertMessage, page: paymentData.successRoute, backdropDismiss: false });
            if (this.modalController) {
                this.modalController.dismiss();
            }
        });
    }
    createPaymentHistory(historyObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const paymentHistoryObj = Object.assign({ paidAt: new Date(), status: 'successful' }, historyObj);
            if (historyObj.mode === 'razorpay') {
                paymentHistoryObj['status'] = 'pending';
            }
            yield this.afs.collection('payment').doc('history').collection('payments').add(paymentHistoryObj);
        });
    }
    getPayableAmount(order) {
        console.log('order', order);
        return order.partialPayment.status ? order.partialPayment.online.amount : (order.amount - (order.walletAmount + order.cashbackAmount));
    }
}
PaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i5.AngularFirestore), i0.ɵɵinject(i6.LabelService), i0.ɵɵinject(i7.SharedNewService), i0.ɵɵinject(i8.AngularFireStorage)); }, token: PaymentService, providedIn: "root" });
